@import "styles/reset.scss";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap');

body, html {
    height: 100%; /* Установить высоту страницы на весь экран */
    margin: 0; /* Убрать отступы */
    padding: 0; /* Убрать отступы */
}

/* html{
    overflow-x: hidden;
    overflow: hidden;
    width: 100vw;
} */

body {
    /* overflow-x: hidden; */
    overflow: hidden;
    /* overflow: auto */
}

td{
    white-space: nowrap;
}

th{
    vertical-align: top;
}

.MuiGrid-root{
    vertical-align: top;
}

.wrapper{
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: #1D162B;
    z-index: 1;
}

.main{
    padding: 120px 120px;
    width: 100%;
    overflow: hidden;
}

.container {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.font{
    font-family: 'Ubuntu', sans-serif;
}

.circle {
    position: absolute;
    flex-shrink: 0;
    border-radius: 50%;
    z-index: -1000;
}

.color1{
    background: #6107ea;
    filter: blur(150.01722717285156px);
    top: 0%;
    left: -10%;
    width: 40vw;
    height: 60vh;
}

.color2{
    background: #73069b;
    filter: blur(150.01722717285156px);
    top: 20%;
    left: 60%;
    transform: translate(-50%, -50%);
    width: 45vw;
    height: 70vh; 
    /* top: 40%;
    left: 60%;
    height: 90vh; */
}

.color3{
    background: #5c1bce;
    filter: blur(150.01722717285156px);
    bottom: 20%;
    left: 10%;
    width: 35vw;
    height: 60vh; 
    z-index: -1000;
}

.icon{
    margin: 0 0 37px 0;
}

.title{
    color: white;
    font-size: 80px;
    font-weight: 500;
    line-height: 92px;
    text-align: center;
}

.header{
    margin: 0 0 37px 0;
}

.description{
    text-align: center;
    color: white;
    line-height: 1.2;
    opacity: 0.8;
}

.headerDescription{
    margin: 0 0 60px 0;
    font-size: 18px;
}

.numberBlock{
    gap: 100px;
    margin: 0 0 80px 0;
}

.numberDesc{
    font-size: 14px;
}


/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {
    .main{
        padding: 10px 10px;
        width: 100%;
        overflow: hidden;
    }

    .title{
        color: white;
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
    }

    .headerDescription{
        margin: 0 0 60px 0;
        font-size: 14px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .main{
        padding: 60px 60px;
        width: 100%;
        overflow: hidden;
    }

    .title{
        color: white;
        font-size: 48px;
        font-weight: 500;
        line-height: 55.15px;
        text-align: center;
    }

    .headerDescription{
        margin: 0 0 60px 0;
        font-size: 14px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .main{
        padding: 80px 80px;
        width: 100%;
        overflow: hidden;
    }

    .title{
        color: white;
        font-size: 60px;
        font-weight: 500;
        line-height: 92px;
        text-align: center;
    }

    .headerDescription{
        margin: 0 0 60px 0;
        font-size: 14px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .main{
        padding: 120px 120px;
        width: 100%;
        overflow: hidden;
    }
    
    .title{
        color: white;
        font-size: 80px;
        font-weight: 500;
        line-height: 92px;
        text-align: center;
    }

    .headerDescription{
        margin: 0 0 60px 0;
        font-size: 18px;
    }
}